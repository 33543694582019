// // src/Components/TopDestination.js
// import React from 'react';
// import '../Styles/TopDestination.css';

// const TopDestination = ({ destinations }) => {
//   return (
//     <div className="top-destination-container">
//       <h2 className="destination-title">Top Destinations</h2>
//       <div className="destination-list">
//         {destinations.map((destination, index) => (
//           <div key={index} className="destination-card">
//             <div className="image-container">
//               <img
//                 src={destination.image}
//                 alt={destination.name}
//                 className="destination-image"
//               />
//             </div>
//             <div className="destination-info">
//               <h3>{destination.name}</h3>
//               <p>{destination.description}</p>
//               <div className="price">${destination.price}</div>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default TopDestination;
// src/Components/TopDestination.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../Styles/TopDestination.css';

const TopDestination = ({ destinations }) => {
  const navigate = useNavigate(); // Hook to programmatically navigate

  const handleCardClick = (destinationName) => {
    navigate(`/destination/${destinationName}`);
  };

  return (
    <div className="top-destination-container">
      <h2 className="destination-title">Top Destinations</h2>
      <div className="destination-list">
        {destinations.map((destination, index) => (
          <div
            key={index}
            className="destination-card"
            onClick={() => handleCardClick(destination.name)}
          >
            <div className="image-container">
              <img
                src={destination.image}
                alt={destination.name}
                className="destination-image"
              />
            </div>
            <div className="destination-info">
              <h3>{destination.name}</h3>
              <p>{destination.description}</p>
              <div className="price">${destination.price}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopDestination;
