
// import React from 'react';
// import '../Styles/ExclusiveDeals.css';

// const ExclusiveDeals = () => {
//   return (
//     <div className="deals-container">
//       {/* The exclusive deals content */}
//       <div className="deals-header">
//         <h2>Exclusive Deals</h2>
//         <a href="#" className="view-all">View All Deals</a>
//       </div>

//       <div className="deals-list">
//         {/* Hot deal and others */}
//         <div className="deal-item hot-deal">
//           <span className="hot-deal-label">HOT DEAL</span>
//           <h3>Fly International at LOWEST FARE</h3>
//           <p>Book Air France-KLM Airline Flight Tickets</p>
//           <a href="#" className="book-now">Book Now</a>
//         </div>
//         <div className="deal-item">
//           <h3>Apply Visa Online</h3>
//           <p>Avail discounts up to Rs.1000* on your Visa Services</p>
//           <a href="#" className="book-now">Book Now</a>
//         </div>
//         <div className="deal-item">
//           <h3>8 European Countries 1 Tour</h3>
//           <p>Starting from 1,89,999</p>
//           <a href="#" className="book-now">Book Now</a>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ExclusiveDeals;
import React from 'react';
import '../Styles/ExclusiveDeals.css';

const ExclusiveDeals = () => {
  return (
    <div className="deals-container ">
      <div className="deals-header ">
        <h2>Exclusive Deals</h2>
        {/*<a href="#" className="view-all">View All Deals</a>*/}
      </div>
      
      <div className="deals-list">
        {/* First Deal */}
        <div className="deal-item hot-deal">
          <span className="hot-deal-label">HOT DEAL</span>
          <img src="https://portwiki.net/wp-content/uploads/2016/12/Travels-Company.jpg" alt="Flight Deals" className="deal-image" />
          <div className="deal-content">
            <h3>Fly International </h3>
            <p>Book Air France-KLM Airline Flight Tickets</p>
            <a href="#" className="book-now">Book Now</a>
          </div>
        </div>

        {/* Second Deal */}
        <div className="deal-item">
          <img src="https://virtuzone.com/wp-content/uploads/2023/05/visa-free-countries.jpg" alt="Visa Services" className="deal-image" />
          <div className="deal-content">
            <h3>Apply Visa Online</h3>
            <p>Avail discounts up to Rs.1000* on your Visa Services</p>
            <a href="#" className="book-now">Book Now</a>
          </div>
        </div>

        {/* Third Deal */}
        <div className="deal-item">
          <img src="https://www.my-travelblog.org/wp-content/uploads/2022/09/Europe1.jpg" alt="Europe Tour" className="deal-image" />
          <div className="deal-content">
            <h3>European Countries 1 Tour</h3>
            <p>Starting from 1,89,999</p>
            <a href="#" className="book-now">Book Now</a>
          </div>
        </div>
          {/* Four Deal */}
          <div className="deal-item ">
          <img src="https://www.hw.com.pk/assets/images/dubai/dubai1.jpg" alt="Europe Tour" className="deal-image" />
          <div className="deal-content no-gap">
            <h3>Dubai Tour</h3>
            <p>Starting from 1,89,999</p>
            <a href="#" className="book-now">Book Now</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExclusiveDeals;
