
import React from 'react';
import {HashRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './Components/SideNav';
import VisaDocuments from './Components/VisaDocuments';
//import Hotel from './Components/Hotel';
//import Flight from './Components/Flight';
import BookingForm from './Components/BookingForm';
import './Styles/App.css';
import OTBDocuments from './Components/OTBDocuments';
import HotelPage from './Components/HotelPage';
import Destination from './Components/Destination';
import MainFlightSearch from './Pages/FlightSearchPage/MainFlightSearch';


function App() {
  return (
    <Router>
      <div className="App">
        <NavBar />
        <div className="content">
          <Routes>
          <Route path="/" element={<BookingForm/> } />
            <Route path="/flights" element={<MainFlightSearch/>} />
            <Route path="/hotels" element={<HotelPage />} />
            <Route path="/visa" element={<VisaDocuments />} />
            <Route path="/otb" element={<OTBDocuments/>} />
            <Route path="/destination/:destinationName" element={<Destination />} />             
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
