

import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Styles/BookingForm.css';
import ExclusiveDeals from './ExclusiveDeals';
import TopDestination from './TopDestination';
import Banner from './Banner';
import Footer from './Footer';

const BookingForm = () => {
  const [tripType, setTripType] = useState('oneWay');
  const [fromCity, setFromCity] = useState('');
  const [toCity, setToCity] = useState('');
  const [returnDate, setReturnDate] = useState('');
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [infants, setInfants] = useState(0);
  const [departureDate, setDepartureDate] = useState('');

  const handleTripTypeChange = (type) => {
    setTripType(type);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    console.log('Search initiated');
  };

  const destinations = [
    {
      image: 'https://ocean6holidays.com/wp-content/uploads/2019/04/honeymoon-in-maldives-1-700x466.jpg',
      name: 'Maldives',
      description: 'Experience the beauty of pristine beaches and clear waters.',
      price: 1500,
    },
    {
      image: 'https://images.movehub.com/wp-content/uploads/2021/04/best-places-to-live-in-paris-1.jpeg',
      name: 'Paris',
      description: 'Explore the romantic city and its stunning architecture.',
      price: 1800,
    },
    {
      image: 'https://media-cdn.tripadvisor.com/media/attractions-splice-spp-720x480/06/6c/39/1e.jpg',
      name: 'New Zealand',
      description: 'Discover the adventure capital of the world.',
      price: 3000,
    },
    {
      image: 'https://www.tripsavvy.com/thmb/x1afjpa58cIAZPCBU2c6l0vO-z4=/2121x1414/filters:fill(auto,1)/chureito-pagoda-and-mt-fuji-5c2fd0d546e0fb00015d4ed0.jpg',
      name: 'Japan',
      description: 'Immerse yourself in rich culture and modern wonders.',
      price: 2200,
    },
    {
      image: 'https://www.travelex.ae/wp-content/uploads/2018/07/1-1.jpg',
      name: 'Dubai',
      description: 'Immerse yourself in rich culture and modern wonders.',
      price: 3200,
    },
  ];

  return (
    <div className='no-gap' >
      <div className='app-container no-gap'>
        <div className="container booking-form ">
          <h3 className="text-center">Book Your Flight</h3>
          <form onSubmit={handleSearch}>
            {/* Trip type buttons */}
            <div className="form-group text-center">
              <div className="btn-group ">
                <button type="button" className={`btn btn-primary ${tripType === 'oneWay' ? 'active' : ''}`} onClick={() => handleTripTypeChange('oneWay')}>One Way</button>
                <button type="button" className={`btn btn-primary ${tripType === 'roundTrip' ? 'active' : ''}`} onClick={() => handleTripTypeChange('roundTrip')}>Round Trip</button>
                <button type="button" className={`btn btn-primary ${tripType === 'multiCity' ? 'active' : ''}`} onClick={() => handleTripTypeChange('multiCity')}>Multi-City</button>
              </div>
            </div>

            {/* Form inputs */}
            <div className="form-row ">
              <div className="form-group col-md-4">
                <label htmlFor="fromCity">From</label>
                <select className="form-control" id="fromCity" value={fromCity} onChange={(e) => setFromCity(e.target.value)}>
                  <option value="">Select City/Airport</option>
                  <option value="New York">New York</option>
                  <option value="Los Angeles">Los Angeles</option>
                  <option value="Chicago">Chicago</option>
                </select>
              </div>

              <div className="form-group col-md-4">
                <label htmlFor="toCity">To</label>
                <select className="form-control" id="toCity" value={toCity} onChange={(e) => setToCity(e.target.value)}>
                  <option value="">Select City/Airport</option>
                  <option value="London">London</option>
                  <option value="Tokyo">Tokyo</option>
                  <option value="Paris">Paris</option>
                </select>
              </div>

              <div className="form-group col-md-4">
                <label htmlFor="departureDate">Departure Date</label>
                <input type="date" className="form-control" id="departureDate" value={departureDate} onChange={(e) => setDepartureDate(e.target.value)} />
              </div>
            </div>

            {tripType !== 'oneWay' && (
              <div className="form-group">
                <label htmlFor="returnDate">Return Date</label>
                <input type="date" className="form-control" id="returnDate" value={returnDate} onChange={(e) => setReturnDate(e.target.value)} />
              </div>
            )}

            <div className="form-row no-gap">
              <div className="form-group col-md-4">
                <label htmlFor="adults">Adults</label>
                <input type="number" className="form-control" id="adults" value={adults} onChange={(e) => setAdults(e.target.value)} min="1" />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="children">Children</label>
                <input type="number" className="form-control" id="children" value={children} onChange={(e) => setChildren(e.target.value)} min="0" />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="infants">Infants</label>
                <input type="number" className="form-control" id="infants" value={infants} onChange={(e) => setInfants(e.target.value)} min="0" />
              </div>
            </div>

            <div className="text-center">
              <button type="submit" className="btn btn-color">Search Flights</button>
            </div>
          </form>
        </div>
      </div>
      <div>
        <ExclusiveDeals />
      </div>

      <div>
        <TopDestination destinations={destinations} />
      </div>
      <div>
        <Banner />
        {/* Other components can go here */}
      </div>
      <div>
        <Footer />
        {/* Other components can go here */}
      </div>

    </div>
  );
};

export default BookingForm;
